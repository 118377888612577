<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover" class="mgb20" style="">
          <div class="user-info">
            <div class="user-info-cont"></div>
          </div>
          <div class="user-info-list">
            现在注册用户总数：
            <span>{{ totalUsers }}</span>
          </div>
          <div class="user-info-list">
            现在发布职位总数：
            <span>{{ totalPublishJobs }}</span>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row>
      <div class="schart-box">
        <div class="content-title">折线图</div>
        <schart class="schart" canvasId="line" :options="options1"></schart>
      </div>
    </el-row>
  </div>
</template>

<script>
// import ChartLine from "../components/chartLine.vue";

import Schart from "vue-schart";
import SYSADMIN from "../api/sys/api_admin.js";

export default {
  name: "businessHome",
  components: {
    Schart,
  },

  data() {
    return {
      totalUsers: undefined,
      totalPublishJobs: undefined,
      options1: {
        type: "line",
        title: {
          text: "最近30天注册用户和发布职位示意图",
        },
        bgColor: "#fbfbfb",
        labels: [],
        datasets: [],
      },
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  watch: {
    totalUsers: function (newValue, oldValue) {},
    totalPublishJobs: function (newValue, oldValue) {},
  },
  computed: {},
  // 方法定义
  methods: {
    init() {
      SYSADMIN.countDailyRegisterUser().then((res) => {
        console.log("user " + JSON.stringify(res));
        if (res.status == 1 && res.data) {
          let dataset = {};
          dataset.label = "注册用户";

          let labels = [];
          let data = [];
          res.data.forEach((element) => {
            labels.push(element.date);
            data.push(element.result);
          });
          dataset.data = data;
          this.options1.datasets.push(dataset);
          this.options1.labels = labels;
        }
      });
      SYSADMIN.countDailyPublishJob().then((res) => {
        console.log("job " + JSON.stringify(res));
        if (res.status == 1 && res.data) {
          let dataset = {};
          dataset.label = "发布职位";

          let labels = [];
          let data = [];
          res.data.forEach((element) => {
            labels.push(element.date);
            data.push(element.result);
          });
          dataset.data = data;
          this.options1.datasets.push(dataset);
        }
      });
      SYSADMIN.countHomeData().then((res) => {
        if (res.status == 1) {
          this.totalUsers = res.data.users;
          this.totalPublishJobs = res.data.jobs;
          if (!this.totalUsers) {
            this.totalUsers = 0;
          }
          if (!this.totalPublishJobs) {
            this.totalPublishJobs = 0;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.schart-box {
  display: block;
  margin: 20px;
}
.schart {
  width: calc(100vw - 311px);
  height: 400px;
}
.content-title {
  clear: both;
  font-weight: 400;
  line-height: 50px;
  margin: 10px 0;
  font-size: 22px;
  color: #1f2f3d;
}
</style>